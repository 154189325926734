<script lang="ts">
  import {
    AreaChartIcon,
    BarChart4Icon,
    Code2Icon,
    FastForwardIcon,
    PackageIcon,
    ReceiptIcon,
    ShapesIcon,
    TimerIcon,
    ZapIcon,
  } from "lucide-svelte";

  import InteractiveImageGeneration from "./InteractiveImageGeneration.svelte";
  import UseCase from "./UseCase.svelte";
</script>

<section class="bg-dark-gray">
  <!-- Flex is used here to disable margin-bottom collapse. -->
  <div class="marketing-container py-16 flex flex-col">
    <h2>Use Cases</h2>

    <UseCase
      titleGreen="Generative AI Inference"
      titleContinued="that scales with you"
      link="/docs/examples/trtllm_latency"
      details={[
        {
          icon: ZapIcon,
          headline: "Fast cold boots",
          sublineHtml:
            "Load gigabytes of weights in seconds with our optimized container file system.",
        },
        {
          icon: Code2Icon,
          headline: "Bring your own code",
          sublineHtml:
            "Deploy anything from custom models to popular frameworks.",
        },
        {
          icon: AreaChartIcon,
          headline: "Seamless autoscaling",
          sublineHtml:
            "Handle bursty and unpredictable load by scaling to thousands of GPUs and back down to zero.",
        },
      ]}
      placement="left"
    >
      <div
        class="h-80 md:h-full flex items-center justify-center md:mr-8 lg:mr-12"
      >
        <InteractiveImageGeneration />
      </div>
    </UseCase>

    <UseCase
      titleGreen="Fine-tuning"
      titleContinued="and training without managing infrastructure"
      link="/docs/examples/llm-finetuning"
      details={[
        {
          icon: FastForwardIcon,
          headline: "Start training immediately",
          sublineHtml:
            "Provision Nvidia A100 and H100 GPUs in seconds. Your drivers and custom packages are already there.",
        },
        {
          icon: TimerIcon,
          headline: "Never wait in line",
          sublineHtml:
            "Run as many experiments as you need to, in parallel. Stop paying for idle GPUs when you’re done.",
        },
        {
          icon: PackageIcon,
          headline: "Cloud storage",
          sublineHtml:
            "Mount weights and data in distributed volumes, then access them wherever they’re needed.",
        },
      ]}
      placement="right"
    >
      <div class="h-80 md:h-full max-h-[600px]">
        <img
          height="1568"
          width="1397"
          class="w-full h-full object-contain"
          src="https://modal-cdn.com/tmp_d4wxk9j_76c56b72.webp"
          alt="Fine-tuning graphic"
          loading="lazy"
        />
      </div>
    </UseCase>

    <UseCase
      titleGreen="Batch processing"
      titleContinued="optimized for high-volume workloads"
      link="/docs/examples/s3_bucket_mount"
      details={[
        {
          icon: BarChart4Icon,
          headline: "Supercomputing scale",
          sublineHtml:
            "Serverless, but for high-performance compute. Run things on massive amounts of CPU and memory.",
        },
        {
          icon: ReceiptIcon,
          headline: "Serverless pricing",
          sublineHtml:
            "Pay only for resources consumed, by the second, as you spin up containers.",
        },
        {
          icon: ShapesIcon,
          headline: "Powerful compute primitives",
          sublineHtml:
            "Simple fan-out parallelism that scales to thousands of containers, with a single line of Python.",
        },
      ]}
      placement="left"
    >
      <div class="h-80 md:h-full">
        <img
          width="787"
          height="693"
          class="w-full h-full object-contain"
          src="https://modal-cdn.com/tmpwql3zd3v_a00ed2e5.webp"
          alt="Batch processing graphic"
          loading="lazy"
        />
      </div>
    </UseCase>
  </div>
</section>
